<template>

<div class="common-layout" style="width: 100%;">
    <el-container>
      <el-aside width="650px" style="height: 100vh; box-shadow :inset -13px 0px 20px -15px #000000;" class="resp-stock-el-aside">
        <object class="objLogo resp-stock-logo" data="media/still/Logo_StillStock.svg"> </object>
        <router-view></router-view>      
      </el-aside>
      <el-container class="resp-stock-el-container"> 
        <el-main style="background: url(/media/still/p12.jpg) no-repeat center center fixed; background-size: cover;"> 
          <Overview />
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, onUnmounted } from "vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import Overview from "@/views/still/deploy/overview.vue"

export default defineComponent({
  name: "auth",
  components: {Overview},
  setup() {
    const store = useStore();

    onMounted(() => {
      store.dispatch(Actions.ADD_BODY_CLASSNAME, "bg-body");
    });

    onUnmounted(() => {
      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "bg-body");
    });
  },
});
</script>

<style>

</style>
